<template>
  <b-modal id="discount-modal">
    <template v-slot:modal-header="">
      <h5>الخصم</h5>
    </template>

    <template v-slot:default="">
      <div class="form-group row">
        <label class="col-sm-4 col-form-label text-muted text-right"
          >الكود</label
        >
        <div class="col-sm-8">
          <input
            type="text"
            class="form-control text-center"
            disabled
            :value="settings.discountCode"
          />
        </div>
      </div>

      <div class="form-group row">
        <label class="col-sm-4 col-form-label text-muted text-right"
          >الجهة المقدمة</label
        >
        <div class="col-sm-8">
          <input
            type="text"
            class="form-control text-center"
            disabled
            :value="selectedDiscount.offerer"
          />
        </div>
      </div>

      <div class="form-group row">
        <label class="col-sm-4 col-form-label text-muted text-right"
          >الخصم</label
        >
        <div class="col-sm-8">
          <input
            type="text"
            class="form-control text-center"
            disabled
            :value="selectedDiscount.rate + '%'"
          />
        </div>
      </div>

      <div class="form-group row">
        <label class="col-sm-4 col-form-label text-muted text-right"
          >التاريخ</label
        >
        <div class="col-sm-8">
          <input
            type="text"
            class="form-control text-center"
            disabled
            :value="getExactDate(new Date())"
          />
        </div>
      </div>

      <GmapMap
        ref="map"
        :center="{ lat: selectedDiscount.lat, lng: selectedDiscount.lng }"
        :zoom="14"
        style="width: 100%; height: 300px"
      >
        <GmapMarker
          :position="{ lat: selectedDiscount.lat, lng: selectedDiscount.lng }"
          :clickable="false"
          :draggable="false"
          @click="
            center = { lat: selectedDiscount.lat, lng: selectedDiscount.lng }
          "
        />
      </GmapMap>

      <div
        class="d-inline-block mt-3"
        @click="$refs.discountImage.click()"
      >
        <div class="m-auto">
          <img
            ref="discountPreview"
            class="id-card-preview m-auto"
            :src="getImage"
            alt=""
          />
        </div>
      </div>
    </template>

    <template v-slot:modal-footer="">
      <!-- <button type="submit" class="btn btn-primary" @click="submit">حفظ</button> -->
      <a href="#" class="text-muted" @click="$bvModal.hide('discount-modal')"
        >إغلاق</a
      >
    </template>
  </b-modal>
</template>

<script>
import DiscountsMixin from "@/mixins/discounts";
import GeneralMixin from "@/mixins/general";

export default {
  mixins: [DiscountsMixin, GeneralMixin],

  computed: {
    getImage() {
      return this.selectedDiscount.image
        ? this.selectedDiscount.image.link
        : require("@/assets/images/image-placeholder.png");
    },
  },

  watch: {
    selectedDiscount(val) {
      console.log("selecteddiscount from modal: ", val);
    },
  },
};
</script>