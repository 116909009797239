import axios from '@/axios'

export default {
    namespaced: true,

    state: {
        records: [],
        selectedRecord: null,
    },

    getters: {
        records: state => state.records,
        selectedRecord: state => state.selectedRecord,
    },

    mutations: {
        SET_RECORDS: (state, payload) => state.records = payload,
        SET_SELECTED_RECORD: (state, payload) => state.selectedRecord = payload,
    },

    actions: {
        async fetchRecords({ commit }) {
            let { data } = await axios.get('agent/leaseRequests')
            commit('SET_RECORDS', data.leaseRequests)
        },

        async clearLeaseRequestsCounter({ rootState }) {
            let { data } = await axios.post('agent/leaseRequestsCounter')

            rootState.auth.user.leaseRequestsCounter = 0

            return data
        }
    }
}