<template>
  <b-modal id="commercial-lease-modal" title="العقد التجاري" size="lg">
    <div v-if="selectedLeaseRequest" class="container py-2">
      <fieldset class="border p-2 mb-3">
        <legend class="w-auto bold" style="font-size: 16px">
          بيانات المؤجر
        </legend>

        <div class="form-group row">
          <label for="nameAr" class="col-md-4 col-form-label text-md-right"
            >رقم الهوية</label
          >

          <div class="col-md-8">
            <input
              type="number"
              class="form-control"
              v-model="selectedLeaseRequest.renterIdNumber"
              disabled
              autofocus
            />
          </div>
        </div>

        <div class="form-group row">
          <label for="nameAr" class="col-md-4 col-form-label text-md-right"
            >الاسم كاملًا</label
          >

          <div class="col-md-8">
            <input
              type="text"
              class="form-control"
              v-model="selectedLeaseRequest.renterName"
              disabled
            />
          </div>
        </div>

        <div class="form-group row">
          <label for="nameAr" class="col-md-4 col-form-label text-md-right"
            >الجوال المسجل بأبشر</label
          >

          <div class="col-md-8">
            <input
              type="number"
              class="form-control"
              v-model="selectedLeaseRequest.renterMobile"
              disabled
            />
          </div>
        </div>

        <div class="form-group row">
          <label for="nameAr" class="col-md-4 col-form-label text-md-right"
            >تاريخ الميلاد</label
          >

          <div class="col-md-8">
            <div class="row">
              <div class="col-4">
                <input
                  type="text"
                  class="form-control"
                  v-model="selectedLeaseRequest.renterBirthDay"
                  placeholder="يوم"
                  disabled
                />
              </div>

              <div class="col-4">
                <input
                  type="text"
                  class="form-control"
                  v-model="selectedLeaseRequest.renterBirthMonth"
                  placeholder="شهر"
                  disabled
                />
              </div>

              <div class="col-4">
                <input
                  type="text"
                  class="form-control"
                  v-model="selectedLeaseRequest.renterBirthYear"
                  placeholder="سنة"
                  disabled
                />
              </div>
            </div>
          </div>
        </div>

        <div class="form-group row">
          <label for="nameAr" class="col-md-4 col-form-label text-md-right"
            >عدد الشقق</label
          >

          <div class="col-md-8">
            <input
              type="number"
              class="form-control"
              v-model="selectedLeaseRequest.renterApartmentsCount"
              disabled
            />
          </div>
        </div>

        <div class="form-group row">
          <label for="nameAr" class="col-md-4 col-form-label text-md-right"
            >عدد الغرف</label
          >

          <div class="col-md-8">
            <input
              type="number"
              class="form-control"
              v-model="selectedLeaseRequest.renterRoomsCount"
              disabled
            />
          </div>
        </div>

        <div class="form-group row">
          <label for="nameAr" class="col-md-4 col-form-label text-md-right"
            >عدد الصالات</label
          >

          <div class="col-md-8">
            <input
              type="number"
              class="form-control"
              v-model="selectedLeaseRequest.renterHallsCount"
              disabled
            />
          </div>
        </div>

        <div class="form-group row">
          <label for="nameAr" class="col-md-4 col-form-label text-md-right"
            >عدد الحمامات</label
          >

          <div class="col-md-8">
            <input
              type="number"
              class="form-control"
              v-model="selectedLeaseRequest.renterBathroomsCount"
              disabled
            />
          </div>
        </div>

        <div class="form-group row">
          <label for="nameAr" class="col-md-4 col-form-label text-md-right"
            >عدد المكيفات</label
          >

          <div class="col-md-8">
            <input
              type="number"
              class="form-control"
              v-model="selectedLeaseRequest.renterACCount"
              disabled
            />
          </div>
        </div>

        <div class="form-group row">
          <label for="nameAr" class="col-md-4 col-form-label text-md-right"
            >مفروش/غير مفروش</label
          >

          <div class="col-md-8">
            <select class="form-control" v-model="selectedLeaseRequest.renterIsFurnished" disabled>
              <option value="مفروش">مفروش</option>
              <option value="غير مفروش">غير مفروش</option>
            </select>
          </div>
        </div>

        <div class="form-group row">
          <label for="nameAr" class="col-md-4 col-form-label text-md-right"
            >نوع عداد الكهرباء</label
          >

          <div class="col-md-8">
            <select class="form-control" v-model="selectedLeaseRequest.renterElectricType" disabled>
              <option value="مستقل">مستقل</option>
              <option value="مشترك">مشترك</option>
            </select>
          </div>
        </div>

        <div class="form-group row">
          <label for="nameAr" class="col-md-4 col-form-label text-md-right"
            >رقم حساب عداد الكهرباء</label
          >

          <div class="col-md-8">
            <input
              type="text"
              class="form-control"
              v-model="selectedLeaseRequest.renterElectricMeterNumber"
              disabled
            />
          </div>
        </div>

        <div class="form-group row">
          <label for="nameAr" class="col-md-4 col-form-label text-md-right"
            >تاريخ بداية العقد</label
          >

          <div class="col-md-8">
            <div class="row">
              <div class="col-4">
                <input
                  type="text"
                  class="form-control"
                  v-model="selectedLeaseRequest.renterLeaseStartDay"
                  placeholder="يوم"
                  disabled
                />
              </div>

              <div class="col-4">
                <input
                  type="text"
                  class="form-control"
                  v-model="selectedLeaseRequest.renterLeaseStartMonth"
                  placeholder="شهر"
                  disabled
                />
              </div>

              <div class="col-4">
                <input
                  type="text"
                  class="form-control"
                  v-model="selectedLeaseRequest.renterLeaseStartYear"
                  placeholder="سنة"
                  disabled
                />
              </div>
            </div>
          </div>
        </div>

        <div class="form-group row">
          <label for="nameAr" class="col-md-4 col-form-label text-md-right"
            >مبلغ الإيجار السنوي</label
          >

          <div class="col-md-8">
            <input
              type="number"
              class="form-control"
              v-model="selectedLeaseRequest.renterLeaseYearlyAmount"
              disabled
            />
          </div>
        </div>

        <div class="form-group row">
          <label for="nameAr" class="col-md-4 col-form-label text-md-right"
            >نوع الدفعات</label
          >

          <div class="col-md-8">
            <select
            disabled
              class="form-control"
              v-model="selectedLeaseRequest.renterLeasePaymentPeriod"
            >
              <option value="شهري">شهري</option>
              <option value="ربع سنوي">ربع سنوي</option>
              <option value="نصف سنوي">نصف سنوي</option>
              <option value="سنوي">سنوي</option>
            </select>
          </div>
        </div>

        <div class="form-group row">
          <label for="nameAr" class="col-md-4 col-form-label text-md-right"
            >الآيبان البنكي</label
          >

          <div class="col-md-8">
            <input
              type="text"
              class="form-control"
              v-model="selectedLeaseRequest.renterIban"
              disabled
            />
          </div>
        </div>

        <div class="form-group row">
          <label for="nameAr" class="col-md-4 col-form-label text-md-right"
            >صورة واضحة للصك</label
          >

          <div class="col-md-8">
            <div
              class="pointer d-inline-block mt-3"
            >
              <div class="m-auto">
                <img
                  ref="leasePreview"
                  class="img-preview m-auto"
                  :src="selectedLeaseRequest.leaseImageLink"
                  alt=""
                />
              </div>
              <input
                ref="leaseImage"
                type="file"
                style="display: none"
                @change="updateLeaseImage"
                accept="image/*"
              />
            </div>
          </div>
        </div>

        <div class="form-group row">
          <label for="nameAr" class="col-md-4 col-form-label text-md-right"
            >صورة رخصة إتمام البناء</label
          >

          <div class="col-md-8">
            <div
              class="pointer d-inline-block mt-3"
            >
              <div class="m-auto">
                <img
                  ref="buildingCompletionPreview"
                  class="img-preview m-auto"
                  :src="selectedLeaseRequest.buildingCompletionImageLink"
                  alt=""
                />
              </div>
              <input
                ref="buildingCompletionImage"
                type="file"
                style="display: none"
                @change="updateBuildingCompletionImage"
                accept="image/*"
              />
            </div>
          </div>
        </div>

        <fieldset class="border p-2 mb-3">
          <legend class="w-auto" style="font-size: 16px">العنوان الوطني</legend>

          <div class="form-group row">
            <label for="nameAr" class="col-md-4 col-form-label text-md-right"
              >رقم المبنى</label
            >

            <div class="col-md-8">
              <input
                type="text"
                disabled
                class="form-control"
                v-model="selectedLeaseRequest.renterBuildingNumber"
              />
            </div>
          </div>

          <div class="form-group row">
            <label for="nameAr" class="col-md-4 col-form-label text-md-right"
              >الرقم الإضافي</label
            >

            <div class="col-md-8">
              <input
                type="text"
                disabled
                class="form-control"
                v-model="selectedLeaseRequest.renterAdditionalNumber"
              />
            </div>
          </div>

          <div class="form-group row">
            <label for="nameAr" class="col-md-4 col-form-label text-md-right"
              >الرمز البريدي</label
            >

            <div class="col-md-8">
              <input
                type="text"
                disabled
                class="form-control"
                v-model="selectedLeaseRequest.renterPostalCode"
              />
            </div>
          </div>

          <div class="form-group row">
            <label for="nameAr" class="col-md-4 col-form-label text-md-right"
              >الموقع على الخريطة</label
            >

            <div class="col-md-8">
              <GmapMap
                v-if="selectedLeaseRequest.type == 'commercial'"
                ref="map"
                :center="{ lat: selectedLeaseRequest.renterLat, lng: selectedLeaseRequest.renterLng }"
                :zoom="14"
                style="width: 100%; height: 300px"
              >
                <GmapMarker
                  :position="{ lat: selectedLeaseRequest.renterLat, lng: selectedLeaseRequest.renterLng }"
                  :clickable="false"
                  :draggable="false"
                />
              </GmapMap>
            </div>
          </div>
        </fieldset>
      </fieldset>

      <fieldset class="border p-2 my-5">
        <legend class="w-auto bold" style="font-size: 16px">
          بيانات المستأجر
        </legend>

        <div class="form-group row">
          <label for="nameAr" class="col-md-4 col-form-label text-md-right"
            >رقم الهوية</label
          >

          <div class="col-md-8">
            <input
              type="number"
              class="form-control"
              v-model="selectedLeaseRequest.residentIdNumber"
              disabled
            />
          </div>
        </div>

        <div class="form-group row">
          <label for="nameAr" class="col-md-4 col-form-label text-md-right"
            >الاسم كاملًا</label
          >

          <div class="col-md-8">
            <input
              type="text"
              class="form-control"
              v-model="selectedLeaseRequest.residentName"
              disabled
            />
          </div>
        </div>

        <div class="form-group row">
          <label for="nameAr" class="col-md-4 col-form-label text-md-right"
            >جوال صاحب المنشأة المسجل بأبشر</label
          >

          <div class="col-md-8">
            <input
              type="number"
              class="form-control"
              v-model="selectedLeaseRequest.residentMobile"
              disabled
            />
          </div>
        </div>

        <div class="form-group row">
          <label for="nameAr" class="col-md-4 col-form-label text-md-right"
            >تاريخ الميلاد</label
          >

          <div class="col-md-8">
            <div class="row">
              <div class="col-4">
                <input
                  type="text"
                  class="form-control"
                  v-model="selectedLeaseRequest.residentBirthDay"
                  placeholder="يوم"
                  disabled
                />
              </div>

              <div class="col-4">
                <input
                  type="text"
                  class="form-control"
                  v-model="selectedLeaseRequest.residentBirthMonth"
                  placeholder="شهر"
                  disabled
                />
              </div>

              <div class="col-4">
                <input
                  type="text"
                  class="form-control"
                  v-model="selectedLeaseRequest.residentBirthYear"
                  placeholder="سنة"
                  disabled
                />
              </div>
            </div>
          </div>
        </div>

        <div class="form-group row">
          <label for="nameAr" class="col-md-4 col-form-label text-md-right"
            >اسم المؤسسة التجارية</label
          >

          <div class="col-md-8">
            <input
              type="text"
              class="form-control"
              v-model="selectedLeaseRequest.residentCompanyName"
              disabled
            />
          </div>
        </div>

        <div class="form-group row">
          <label for="nameAr" class="col-md-4 col-form-label text-md-right"
            >صورة السجل التجاري ساري المفعول</label
          >

          <div class="col-md-8">
            <div
              class="pointer d-inline-block mt-3"
            >
              <div class="m-auto">
                <img
                  ref="crPreview"
                  class="img-preview m-auto"
                  :src="selectedLeaseRequest.crImageLink"
                  alt=""
                />
              </div>
              <input
                ref="crImage"
                type="file"
                style="display: none"
                @change="updateCrImage"
                accept="image/*"
              />
            </div>
          </div>
        </div>
      </fieldset>
    </div>

    <template v-slot:modal-footer>
      <div class="w-100">
        <button
          @click="$bvModal.hide('commercial-lease-modal')"
          class="float-left btn btn-light"
          style="box-shadow: none"
        >
          إغلاق
        </button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import LeaseRequestMixin from '@/mixins/leaseRequests'

export default {
  mixins: [LeaseRequestMixin],
};
</script>