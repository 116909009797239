<template>
  <li>
    <a href="#!" @click.prevent="$bvModal.show('commission-modal')">
      <div class="row">
        <div class="col-12 col-sm-6 col-md-2 panel-info text-center text-sm-right">
          <span class="text-muted small">العقد: </span>
          #{{ commission.agreementNumber }}
        </div>

        <div class="col-12 col-sm-6 col-md-2 panel-info text-center">
          <span class="text-muted small">التاريخ: </span>
          {{ getExactDateMin(commission.date) }}
        </div>

        <div class="col-12 col-sm-6 col-md-2 panel-info text-center">
          <span class="text-muted small">المبلغ: </span>
          <money :amount="commission.totalAmount" />
        </div>

        <div class="col-12 col-sm-6 col-md-2 panel-info text-center">
          <span class="text-muted small">المدفوع: </span>
          <money :amount="commission.paidAmount" />
        </div>

        <div class="col-12 col-sm-6 col-md-2 panel-info text-center">
          <span class="text-muted small">المتبقي: </span>
          <money :amount="commission.remainingAmount" />
        </div>

        <div class="col-12 col-sm-6 col-md-2 panel-info text-center">
          <!-- <span class="text-muted small">الحالة: </span> -->

          <span
            class="badge"
            :class="{
              'badge-danger': !isPaid,
              'badge-success': isPaid,
            }"
          >
            {{ isPaid ? "مسدد" : "غير مسدد" }}
          </span>
        </div>
      </div>
    </a>
  </li>
</template>

<script>
export default {
  computed: {
    isPaid() {
      return this.commission.totalAmount == this.commission.paidAmount;
    },
  },
  
  props: ["commission"],
};
</script>