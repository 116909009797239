import axios from '@/axios'

export default {
    namespaced: true,

    state: {
        agents: [],
        selectedAgent: null
    },

    getters: {
        agents: state => state.agents,
        selectedAgent: state => state.selectedAgent,
        selectedAgents: state => {
            return state.agents.filter(m => m.selected)
        },
    },

    mutations: {
        SET_AGENTS: (state, payload) => state.agents = payload,
        SET_SELECTED_AGENT: (state, payload) => state.selectedAgent = payload,
    },

    actions: {
        async fetchAgents({ commit }) {
            let { data } = await axios.get('agents')
            data.agents.forEach(m => m.selected = false)
            commit('SET_AGENTS', data.agents)

        },

    }
}