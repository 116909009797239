import axios from '@/axios'

export default {
    namespaced: true,

    state: {
        requests: [],
        selectedRequest: null
    },

    getters: {
        requests: state => state.requests,
        selectedRequest: state => state.selectedRequest,
        selectedRequests: state => {
            return state.requests.filter(m => m.selected)
        },
    },

    mutations: {
        SET_REQUESTS: (state, payload) => state.requests = payload,
        SET_SELECTED_REQUEST: (state, payload) => state.selectedRequest = payload,
        SELECT_ALL: state => {
            state.requests.forEach(m => m.selected = true)
        },
        DESELECT_ALL: state => {
            state.requests.forEach(m => m.selected = false)
        },
        SELECT_REQUEST: (state, payload) => {
            state.requests[state.requests.indexOf(payload)].selected = true
        },

        DESELECT_REQUEST: (state, payload) => {
            state.requests[state.requests.indexOf(payload)].selected = false
        },
    },

    actions: {
        async fetchRequests({ commit }) {
            let { data } = await axios.get('agent/requests')
            data.requests.forEach(m => m.selected = false)
            commit('SET_REQUESTS', data.requests)

        },

        toggleRequestsSelection({ commit, getters, state }) {
            if (getters.selectedRequests.length < state.requests.length) {
                commit('SELECT_ALL')
            } else {
                commit('DESELECT_ALL')
            }
        },

        toggleRequestSelection({ commit, state }, payload) {
            let currentState = state.requests[state.requests.indexOf(payload)].selected
            if (currentState) {
                commit('SELECT_REQUEST', payload)
            } else {
                commit('DESELECT_REQUEST', payload)
            }
        },

        async deleteSelectedRequests({ getters, dispatch }) {
            let ids = []
            getters.selectedRequests.forEach(doc => ids.push(doc.id))
            let { data } = await axios.delete('agent/requests', { params: { ids } })
            dispatch('fetchRequests')
            return data
        },

        async clearRequestsCounter({ rootState }) {
            let { data } = await axios.post('agent/clearRequestsCounter')

            rootState.auth.user.requestsCount = 0

            return data
        },

        async updateRequest({ getters }, images) {
            let selectedRequest = getters.selectedRequest
            let formData = new FormData()

            if (images) {
                for (let i = 0; i < images.length; i++) {
                    formData.append('images[' + i + ']', images[i])
                }
            }

            selectedRequest.images = null

            selectedRequest.agent_id = selectedRequest.agent.id
            selectedRequest.agent = null

            for (const [key, value] of Object.entries(selectedRequest)) {
                if (value !== null) {
                    formData.append(key, value)
                }
            }

            let { data } = await axios.post(`agent/requests/${selectedRequest.id}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
            })

            return data
        },

        async deleteImage(_, image) {
            let { data } = await axios.delete(`images/${image.id}`)

            return data
        },
    }
}