<template>
  <b-modal id="add-renting-modal">
    <template v-slot:modal-header="">
      <h5>إضافة مستأجر</h5>
    </template>

    <template v-slot:default="">
      <div class="form-group text-right">
        <label class="small">اسم العميل:</label>
        <input
          class="form-control"
          placeholder="اسم العميل"
          :value="clientsSearchTerm"
          @keyup="clientsSetSearchTerm($event.target.value)"
        />
      </div>

      <div v-if="clientsSearchTerm && clientsFilter.length > 0" class="form-group">
        <div class="clients-list">
          <div v-for="(client, i) in clientsFilter" :key="i" class="list-row" @click="addRentingTap(client)">
            {{ client.name }}
          </div>
        </div>
      </div>
    </template>

    <template v-slot:modal-footer="">
      <button type="submit" class="btn btn-primary" @click="submit">حفظ</button>
      <a
        href="#"
        class="text-muted"
        @click="$bvModal.hide('add-renting-modal')"
        >إغلاق</a
      >
    </template>
  </b-modal>
</template>

<script>
import ClientsMixin from '@/mixins/clients'
import PropertiesMixin from '@/mixins/properties'

export default {
    mixins: [ClientsMixin, PropertiesMixin],

  data() {
    return {
      selectedAgent: {
        name: "dfsfd",
        email: "adjfsd@dsf.com",
        mobile: "21121212",
        workAddress: "sdfsdfsdf",
        idNumber: "121212",
        idCardImage: "http://via.placeholder.com/100",
      },
    };
  },

  methods: {
    submit() {},

    updateImage(e) {
      this.$refs.idCardPreview.src = URL.createObjectURL(e.target.files[0]);
    },

    addRentingTap (client) {
        this.selectClient(client)
        this.addRenting().then((res) => {
            // this.showSwal(res)
            if (res.success) {
                this.$bvModal.hide('add-renting-modal')
            }
        })
    }
  },
};
</script>

<style scoped>
.clients-list {
  min-height: 200px;
    width: 100%;
    box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    overflow-y: scroll;
}

.clients-list .list-row {
  padding: 15px;
    text-align: right;
    font-size: 20px;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.1);
    cursor: pointer;
}

.clients-list .list-row:hover {
    background-color: rgba(0, 0, 0, 0.03);
}
</style>