import Vue from 'vue'
import VueRouter from 'vue-router'

import GuestLayout from '@/layouts/guest'
import MainLayout from '@/layouts/main'
import DocEditLayout from '@/layouts/doc-edit'
Vue.component('guest-layout', GuestLayout)
Vue.component('main-layout', MainLayout)
Vue.component('doc-edit-layout', DocEditLayout)

import Home from '../views/user/home'
import Properties from '../views/user/properties/index'
import ShowProperty from '../views/user/properties/show'
import CreateProperty from '../views/user/properties/create'
import Clients from '../views/user/clients/index'
import ShowUser from '../views/user/clients/show'
import Discounts from '../views/user/discounts'
import Commissions from '../views/user/commissions'
import Notifications from '../views/user/notifications'
import Maintenance from '../views/user/maintenance'
import LeaseRequests from '../views/user/leaseRequests'
import Requests from '../views/user/requests'
import Bills from '../views/user/bills'
import Login from '../views/user/auth/login'
// import Register from '../views/user/auth/register'
import VerifyEmail from '../views/user/auth/verify-email'
import ForgotPassword from '../views/user/auth/forgot-password'
import ResetPassword from '../views/user/auth/reset-password'

import auth from '@/store/auth'

Vue.use(VueRouter)

const routes = [{
        path: '/',
        component: Home,
        meta: {
            isAuth: true,
        }
    },
    {
        path: '/home',
        name: 'home',
        component: Home,
        meta: {
            isAuth: true,
        }
    },
    {
        path: '/properties',
        name: 'properties.index',
        component: Properties,
        meta: {
            isAuth: true,
        }
    },
    {
        path: '/properties/:propertyNumber',
        name: 'properties.show',
        component: ShowProperty,
        meta: {
            isAuth: true,
        }
    },
    {
        path: '/clients',
        name: 'clients.index',
        component: Clients,
        meta: {
            isAuth: true,
        }
    },
    {
        path: '/clients/:userNumber',
        name: 'users.show',
        component: ShowUser,
        meta: {
            isAuth: true,
        }
    },
    {
        path: '/properties/create',
        name: 'properties.create',
        component: CreateProperty,
        meta: {
            isAuth: true,
        }
    },
    {
        path: '/commissions',
        name: 'commissions',
        component: Commissions,
        meta: {
            isAuth: true,
        }
    },
    {
        path: '/discounts',
        name: 'discounts',
        component: Discounts,
        meta: {
            isAuth: true,
        }
    },
    {
        path: '/notifications',
        name: 'notifications',
        component: Notifications,
        meta: {
            isAuth: true,
        }
    },
    {
        path: '/maintenance',
        name: 'maintenance',
        component: Maintenance,
        meta: {
            isAuth: true,
        }
    },
    {
        path: '/bills',
        name: 'bills',
        component: Bills,
        meta: {
            isAuth: true,
        }
    },
    {
        path: '/leaseRequests',
        name: 'leaseRequests',
        component: LeaseRequests,
        meta: {
            isAuth: true,
        }
    },
    {
        path: '/requests',
        name: 'requests',
        component: Requests,
        meta: {
            isAuth: true,
        }
    },
    {
        path: '/login',
        name: 'login',
        component: Login,
        meta: {
            isGuest: true,
            layout: 'guest-layout'
        }
    },
    // {
    //     path: '/register',
    //     name: 'register',
    //     component: Register,
    //     meta: {
    //         isGuest: true,
    //         layout: 'guest-layout'
    //     }
    // },
    {
        path: '/verify-email/:email/:token',
        name: 'verifyEmail',
        component: VerifyEmail,
        meta: {
            // isGuest: true,
            layout: 'guest-layout'
        }
    },
    {
        path: '/forgot-password',
        name: 'forgotPassword',
        component: ForgotPassword,
        meta: {
            isGuest: true,
            layout: 'guest-layout'
        }
    },
    {
        path: '/reset-password/:email/:token',
        name: 'resetPassword',
        component: ResetPassword,
        meta: {
            isGuest: true,
            layout: 'guest-layout'
        }
    },
    // {
    //     path: '/checkout',
    //     name: 'checkout',
    //     component: Checkout,
    //     meta: {
    //         isAuth: true,
    //     }
    // }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior() {
        return { x: 0, y: 0 }
    }
})

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.isAuth)) {
        if (auth.state.token === null || auth.state.user === null) {
            next({
                name: 'login',
                query: { redirect: to.fullPath }
            })
        } else {
            next()
        }
    } else if (to.matched.some(record => record.meta.isGuest)) {
        if (auth.state.token !== null || auth.state.user !== null) {
            next({
                name: 'home',
            })
        } else {
            next()
        }
    } else {
        next()
    }
})

export default router