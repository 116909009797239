<template>
  <div class="">
    <PanelList
      class="mt-5"
      :items="commissions"
      title="العمولات"
      :isAllSelected="isAllSelected"
      @toggle-items-selection="toggleCommissionsSelection()"
      :canSelectAll="false"
    >
      <CommissionsPanelItem
        v-for="(commission, i) in commissions"
        :key="i"
        :commission="commission"
      />
    </PanelList>

    <!-- <CommissionsModal /> -->
  </div>
</template>

<script>
import PanelList from "@/components/general/panel-list";
import CommissionsPanelItem from "@/components/panel-items/commission";
import CommissionsMixin from '@/mixins/commissions'
// import CommissionsModal from "@/components/modals/commission";

export default {
  mixins: [CommissionsMixin],

  components: {
    PanelList,
    CommissionsPanelItem,
    // DiscountModal,
  },
};
</script>