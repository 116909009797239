import axios from '@/axios'

export default {
    namespaced: true,

    state: {
        categories: [],
        selectedCategory: null
    },

    getters: {
        categories: state => state.categories,
        selectedCategory: state => state.selectedCategory
    },

    mutations: {
        SET_CATEGORIES: (state, payload) => state.categories = payload,
        SELECT_CATEGORY: (state, payload) => state.selectedCategory = payload
    },

    actions: {
        async fetchCategories({ commit }) {
            let { data } = await axios.get('categories')
            commit('SET_CATEGORIES', data.categories)
        },

        // async updateCategories({ dispatch, getters }) {
        //     let { data } = await axios.put('agent/categories', { categories: getters.categories })

        //     dispatch('fetchCategories')

        //     return data
        // },

        // async deleteCategory({ dispatch }, category) {
        //     let { data } = await axios.delete(`agent/categories/${category.id}`)

        //     dispatch('fetchCategories');

        //     return data
        // },

        // addCategory({ state }) {
        //     state.categories.push({})
        // }
    }
}