<template>
  <div class="container">
    <div class="m-auto w-70 pb-5">
      <div class="form-group text-right">
        <label class="small">القسم:</label>
        <select class="form-control" v-model="property.category_id">
          <option :value="1">شقق عزاب</option>
        </select>
      </div>

      <div class="form-group text-right">
        <label class="small">عدد الغرف:</label>
        <input
          class="form-control"
          placeholder="عدد الغرف"
          v-model="property.roomsCount"
          type="number"
        />
        <div v-if="!property.roomsCount" class="small text-red">
          الإدخال مطلوب.
        </div>
      </div>

      <div class="form-group text-right">
        <label class="small">الصالة:</label>
        <select class="form-control" v-model="property.isWithHall">
          <option :value="true">متوفرة</option>
          <option :value="false">متوفرة</option>
        </select>
      </div>

      <div class="form-group text-right">
        <label class="small">عدد دورات المياه:</label>
        <input
          class="form-control"
          placeholder="عدد دورات المياه"
          v-model="property.bathsCount"
          type="number"
        />
        <div v-if="!property.bathsCount" class="small text-red">
          الإدخال مطلوب.
        </div>
      </div>

      <div class="form-group text-right">
        <label class="small">نظام المطبخ:</label>
        <select class="form-control" v-model="property.isAmericanKitchen">
          <option :value="true">أمريكي</option>
          <option :value="false">مستقل</option>
        </select>
      </div>

      <div class="form-group text-right">
        <label class="small">الأثاث:</label>
        <select class="form-control" v-model="property.isFurnished">
          <option :value="true">متوفر</option>
          <option :value="false">غير متوفر</option>
        </select>
      </div>

      <div class="form-group text-right">
        <label class="small">الوصف:</label>
        <textarea
          class="form-control"
          placeholder="الوصف"
          v-model="property.description"
        ></textarea>
        <div v-if="!property.description" class="small text-red">
          الإدخال مطلوب.
        </div>
      </div>

      <div class="form-group text-right">
        <label class="small">الصور:</label>
        <br>
        <input
          type="file"
          ref="images"
          multiple
          accept="image/*"
        />
      </div>

      <div class="form-group text-right">
        <label class="small">رابط الفيديو:</label>
        <input
          class="form-control"
          placeholder="انسخ الرابط من المتصفح كما هو"
          v-model="property.videoLink"
          type="url"
        />
      </div>

      <div class="map-wrapper mt-5">
        <GmapMap
          ref="map"
          :center="{ lat: property.lat, lng: property.lng }"
          :zoom="14"
          style="width: 100%; height: 300px"
          @click="updateMarker"
        >
          <GmapMarker
            :position="{ lat: property.lat, lng: property.lng }"
            :clickable="false"
            :draggable="false"
            @click="center = { lat: property.lat, lng: property.lng }"
          />
        </GmapMap>
      </div>

      <button class="btn btn-block btn-primary btn-lg mt-4">
          <fa icon="save" class="ml-2" />
          <span>حفظ</span>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
        property: {
            number: '12232334',
            category_id: 1,
            roomsCount: 1,
            isWithHall: true,
            isAmericanKitchen: false,
            description: 'أبجد هوز حطي كلمن سعفص قرشت',
            lat: 24.6274630000,
            lng: 46.6913850000,
            bathsCount: 1,
            isFurnished: true
        }
    };
  },

  methods: {
    updateMarker(e) {
      this.property.lat = e.latLng.lat();
      this.property.lng = e.latLng.lng();
    },
  },
};
</script>