<template>
  <b-modal id="maintenance-modal">
    <template v-slot:modal-header="">
      <h5>طلب عميل/صيانة</h5>
    </template>

    <template v-slot:default="">
      <div class="form-group">
        <label for="nameAr" class="text-muted">حدد الموظف</label>

        <multiselect
          dir="rtl"
          class="text-right"
          v-model="maintenance.agent"
          :options="agents"
          placeholder="حدد الموظف"
          label="name"
          track-by="name"
          selectLabel="اضغظ Enter للتحديد"
          deselectLabel="اضغط Enter لإلغاء التحديد"
          autofocus
        >
          <template><span slot="noResult">لا توجد نتائج</span></template>
          <template><span slot="noOptions">لا توجد خيارات</span></template>
        </multiselect>
      </div>

      <div class="form-group text-right">
        <label class="small">رقم العقار:</label>
        <input
          class="form-control"
          placeholder="رقم العقار"
          type="number"
          v-model="maintenance.property.number"
        />
      </div>

      <div class="form-group mt-3 text-right">
        <label class="small">التفاصيل:</label>
        <textarea
          class="form-control"
          placeholder="التفاصيل"
          rows="5"
          v-model="maintenance.body"
        ></textarea>
      </div>

      <div class="form-group mt-3 text-right">
        <label class="small">الصور:</label>
        <ImagesWrapper
          :images="allImages"
          @images-changed="updateImages($event)"
          @delete-image="deleteImageTap($event)"
        />
      </div>
    </template>

    <template v-slot:modal-footer="">
      <button type="submit" class="btn btn-primary" @click="submit">حفظ</button>
      <a href="#" class="text-muted" @click.prevent="$bvModal.hide('maintenance-modal')"
        >إغلاق</a
      >
    </template>
  </b-modal>
</template>

<script>
import MaintenancesMixin from "@/mixins/maintenances";
import PropertiesMixin from "@/mixins/properties";
import AgentsMixin from '@/mixins/agents'

export default {
  mixins: [MaintenancesMixin, PropertiesMixin, AgentsMixin],

  data() {
    return {
      maintenance: {
        agent: {},
        property: {
          number: null,
        },
        body: null,
        images: []
      },
      newImages: []
    };
  },

  mounted() {
    this.buildMaintenance();
  },

  methods: {
    buildMaintenance() {
      console.log("selectedMaintenance: ", this.selectedMaintenance);
      if (this.selectedMaintenance) {
        this.maintenance.body = this.selectedMaintenance.body;
        this.maintenance.images = this.selectedMaintenance.images;
        this.maintenance.property = this.selectedMaintenance.property
        this.maintenance.agent = this.selectedMaintenance.agent
      } else {
        this.maintenance.body = null;
        this.maintenance.images = []
        this.maintenance.property = {
          number: null
        }
        this.maintenance.agent = {}
        this.newImages = []
      }
    },
    
    submit() {
      this.maintenance.images = this.newImages
      this.saveMaintenance(this.maintenance).then((res) => {
        console.log('return res: ', res)
        if (res.success) {
          this.$bvModal.hide("maintenance-modal");
          // this.showSwal(res)
          // this.selectedProperty.maintenances.push(this.maintenance);
        } else {
          this.showSwal(res);
        }
          this.newImages = []
      });
    },

    updateImages(files) {
      files.forEach((file) => {
        // file.src = URL.createObjectURL(file);
        this.newImages.push(file);
      });
    },

    deleteImageTap(image) {
      // console.log(image)
      let foundImage = this.selectedMaintenance ? this.selectedMaintenance.images.find((img) => img == image) : null;

      if (foundImage) {
        this.deleteImage(image).then((res) => {
          if (res.success) {
            this.selectedMaintenance.images.splice(this.selectedMaintenance.images.indexOf(image), 1);
          }
        });
      } else {
        this.newImages.splice(this.newImages.indexOf(image), 1);
      }
    },
  },

  computed: {
    allImages() {
      this.newImages.forEach((newImage) => {
        newImage.src = URL.createObjectURL(newImage);
      });
      return this.maintenance.images ? this.maintenance.images.concat(this.newImages) : this.newImages;
    },
  },

  watch: {
    selectedMaintenance () {
      this.buildMaintenance()
    }
  }
};
</script>