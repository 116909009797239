<template>
  <div>
    <PanelList
      class="mt-3"
      :items="leaseRequests"
      title="طلبات العقود"
      :canSelectAll="false"
    >
      <LeaseRequestPanelItem
        v-for="(leaseRequest, i) in leaseRequests"
        :key="i"
        :leaseRequest="leaseRequest"
      />
    </PanelList>

    <ResidentialLeaseModal />
    <CommercialLeaseModal />
    <SubLeaseModal />
  </div>
</template>

<script>
import PanelList from "@/components/general/panel-list";
import LeaseRequestPanelItem from "@/components/panel-items/leaseRequest";
import LeaseRequestsMixin from '@/mixins/leaseRequests'
import ResidentialLeaseModal from '@/components/modals/residential-lease'
import CommercialLeaseModal from '@/components/modals/commercial-lease'
import SubLeaseModal from '@/components/modals/sub-lease'

export default {
  mixins: [LeaseRequestsMixin],

  mounted () {
    this.clearLeaseRequestsCounter()
  },

  components: {
    PanelList,
    LeaseRequestPanelItem,
    ResidentialLeaseModal,
    CommercialLeaseModal,
    SubLeaseModal
  },
};
</script>