<template>
  <div>
    <div class="flex-wrapper" style="min-height: 500px">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-8">
            <div class="card">
              <div class="card-header">إعادة تعيين كلمة المرور</div>

              <div class="card-body">
                <!-- @if (session('status'))
                <div class="alert alert-success" role="alert">
                  {{ session("status") }}
                </div>
                @endif -->

                <form method="POST">
                  <div class="form-group row">
                    <label
                      for="password"
                      class="col-md-4 col-form-label text-md-right"
                      >كلمة المرور الجديدة</label
                    >

                    <div class="col-md-6">
                      <input
                        id="password"
                        type="password"
                        class="form-control"
                        :class="{ 'is-invalid': passwordError }"
                        name="password"
                        v-model="form.password"
                        required
                        autofocus
                      />

                      <!-- @error('email')
                      <span class="invalid-feedback" role="alert">
                        <strong>{{ $message }}</strong>
                      </span>
                      @enderror -->
                    </div>
                  </div>

                  <div class="form-group row">
                    <label
                      for="password_confirmation"
                      class="col-md-4 col-form-label text-md-right"
                      >تأكيد كلمة المرور</label
                    >

                    <div class="col-md-6">
                      <input
                        id="password_confirmation"
                        type="password"
                        class="form-control"
                        :class="{ 'is-invalid': passwordConfirmationError }"
                        name="password_confirmation"
                        v-model="form.password_confirmation"
                        required
                      />

                      <!-- @error('email')
                      <span class="invalid-feedback" role="alert">
                        <strong>{{ $message }}</strong>
                      </span>
                      @enderror -->
                    </div>
                  </div>

                  <div class="form-group row mb-0">
                    <div class="col-md-6 offset-md-4">
                      <button type="button" @click="submit" class="btn btn-primary">
                        إعادة التعيين
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AuthMixin from '@/mixins/auth'

export default {
    mixins: [AuthMixin],

    data () {
        return {
            form: {
                email: this.$route.params.email,
                token: this.$route.params.token,
                password: null,
                password_confirmation: null
            },
            passwordError: false,
            passwordConfirmationError: false
        }
    },

    mounted () {
        this.verifyPasswordResetToken(this.form).then((res) => {
            if (!res.success) {
                this.showSwal(res)
                this.$router.push({ name: 'login' })
            }
        })
    },

    methods: {
        submit () {
            this.resetPassword(this.form).then((res) => {
                this.showSwal(res)
                this.$router.push({ name: 'login' })
            })
        }
    }
};
</script>