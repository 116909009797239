<template>
  <div class="flex-wrapper" style="min-height: 600px">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-8">
          <div class="card">
            <div class="card-header">تسجيل دخول الموظفين</div>

            <div class="card-body">
              <form method="POST">
                <div class="form-group row">
                  <label
                    for="mobile"
                    class="col-md-4 col-form-label text-md-right"
                    >رقم الجوال</label
                  >

                  <div class="col-md-6">
                    <input
                      id="mobile"
                      type="number"
                      class="form-control"
                      :class="{ 'is-invalid': mobileError }"
                      name="mobile"
                      v-model="form.mobile"
                      required
                      autocomplete="mobile"
                      autofocus
                      @keydown.enter="submit"
                    />

                    <!-- <span class="invalid-feedback" role="alert">
                        <strong>{{ $message }}</strong>
                      </span> -->
                  </div>
                </div>

                <div class="form-group row">
                  <label
                    for="password"
                    class="col-md-4 col-form-label text-md-right"
                    >كلمة المرور</label
                  >

                  <div class="col-md-6">
                    <input
                      id="password"
                      type="password"
                      class="form-control"
                      :class="{ 'is-invalid': passwordError }"
                      name="password"
                      required
                      autocomplete="current-password"
                      v-model="form.password"
                      @keydown.enter="submit"
                    />

                    <!-- <span class="invalid-feedback" role="alert">
                        <strong>{{ $message }}</strong>
                      </span> -->
                  </div>
                </div>

                <!-- <div class="form-group row">
                  <div class="col-md-6 offset-md-4">
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        name="remember"
                        id="remember"
                        :checked="form.rememberMe"
                      />

                      <label class="form-check-label" for="remember">
                        تذكرني
                      </label>
                    </div>
                  </div>
                </div> -->

                <div class="form-group row mb-0">
                  <div class="col-md-6 offset-md-4">
                    <button
                      type="button"
                      class="btn btn-primary"
                      @click="submit"
                    >
                      دخول
                    </button>

                    <!-- <router-link
                      class="btn-link mr-3 float-left"
                      :to="{ name: 'forgotPassword' }"
                    >
                      نسيت كلمة المرور؟
                    </router-link> -->
                  </div>
                </div>
              </form>
              <hr class="w-70 mt-4 mb-3" />
              <div class="text-center text-muted">
                <div class="row">
                  <!-- <div class="col">
                    ليس لديك حساب؟
                    <router-link :to="{ name: 'register' }" class="btn-link">
                      إنشاء حساب جديد </router-link
                    >
                  </div> -->

                  <div class="col">
                    <a class="small" :href="VUE_APP_USER_URL + '/login'">
                      دخول العملاء
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        mobile: null,
        password: null,
        // rememberMe: false,
      },
      mobileError: false,
      passwordError: false,
    };
  },

  methods: {
    submit() {
      this.$store
        .dispatch("auth/login", this.form)
        .then(() => {
          // this.$router.replace({
          //     name: 'Home'
          // })
          this.$router.push(this.$route.query.redirect || { name: "home" });
        })
        .catch(() => {
          this.$swal({
            title: "خطأ في الإدخال",
            icon: "error",
            showConfirmButton: true,
          });
        });
    },
  },
};
</script>