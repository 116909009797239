import { mapActions, mapMutations, mapGetters } from 'vuex';

export default {
    methods: {
        ...mapActions({
            clearLeaseRequestsCounter: 'leaseRequests/clearLeaseRequestsCounter'
        }),

        ...mapMutations({
            selectLeaseRequest: 'leaseRequests/SET_SELECTED_RECORD'
        })
    },

    computed: {
        ...mapGetters({
            leaseRequests: 'leaseRequests/records',
            selectedLeaseRequest: 'leaseRequests/selectedRecord'
        })
    }
}