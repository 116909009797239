<template>
  <li>
    <a href="#!" @click.prevent="showClientTap">
      <div class="row">
        <div
          class="col-12 col-sm-6 col-md-4 panel-info text-center text-sm-right"
        >
          <div @click.stop class="d-inline">
            <b-form-checkbox
              class="d-inline pl-0"
              v-model="client.selected"
              :value="true"
              @change.native="toggleClientSelection(client)"
            >
            </b-form-checkbox>
          </div>
          {{ client.name }}
        </div>

        <div class="col-12 col-sm-6 col-md-2 panel-info text-center">
          <!-- {{ client.email }} -->
        </div>

        <div class="col-12 col-sm-6 col-md-2 panel-info text-center">
          {{ client.mobile }}
        </div>

        <div
          class="col-12 col-sm-6 col-md-4 panel-info text-center"
          @click.stop
        >
          <button
            v-if="client.agreement"
            class="btn btn-sm btn-warning ml-2"
            @click="$emit('show-agreement-tap', client)"
          >
            <fa icon="file-contract" class="ml-2" />
            <span>عرض العقد</span>
          </button>

          <!-- <button class="btn btn-sm btn-info" @click.stop="addNotificationTap">
            <fa icon="bell" />
          </button> -->
        </div>
      </div>
    </a>
  </li>
</template>

<script>
import ClientsMixin from "@/mixins/clients";

export default {
  mixins: [ClientsMixin],

  methods: {
    showClientTap() {
      this.selectClient(this.client);
      this.$bvModal.show("client-modal");
    },

    addNotificationTap() {
      this.selectClient(this.client);
      this.$bvModal.show("notification-modal");
    },
  },

  props: ["client"],
};
</script>